import { useState, useEffect } from 'react';

import { MOBILE_VIEW_MAX_WIDTH } from '../constants/layoutBreakpoints';

export const useScreenMatchOnMobile = (): boolean => {
  // typeof window !== 'undefined' is used as workaround for gatsby build which doesn't permit
  // usage of window ref without this check outside useEffect or lifecycle methods
  const [isMobile, setIsMobile] = useState(
    (typeof window !== 'undefined' ? window.innerWidth : 0) < MOBILE_VIEW_MAX_WIDTH,
  );

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < MOBILE_VIEW_MAX_WIDTH);
    };

    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return isMobile;
};
