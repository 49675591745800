import React, { useEffect, useState } from 'react';

import { classNames } from 'utils/classNames';

import { showCookiesConstant } from './constants';

import styles from './CookiesPopup.module.css';

const CookiesPopup = (): React.ReactElement => {
  const [showCookies, setShowCookies] = useState(true);

  useEffect(
    () =>
      setShowCookies(
        typeof window === 'undefined' ||
          window.localStorage.getItem(showCookiesConstant) !== 'false',
      ),
    [],
  );

  const handleCookiesClick = (): void => {
    setShowCookies(false);

    window.localStorage.setItem(showCookiesConstant, 'false');
  };

  return (
    <div className={classNames([showCookies ? styles.cookiesBlock : styles.hidden])}>
      <div className={styles.container}>
        <div className={styles.text}>
          This website uses cookies: By continuing to browse this site you accept this policy.
        </div>
        <a
          className={styles.link}
          href="https://www.bcdtravel.com/cookie-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
        <button type="button" className={styles.button} onClick={handleCookiesClick}>
          Got it!
        </button>
      </div>
    </div>
  );
};

export default CookiesPopup;
