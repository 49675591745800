require('babel-polyfill');

exports.onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
};

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;

  const scrollToTopRoutes = [
    '/travel-policies/hotels/at-glance',
    '/travel-policies/airlines/at-glance',
    '/travel-policies/airlines/all',
    '/travel-policies/airports/all',
    '/travel-policies/hotels/all',
    '/travel-policies/car-rentals/all',
    '/travel-policies/rail/all',
    '/need-to-know',
    '/covid-19-map',
  ];

  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.includes(pathname)) window.scrollTo(0, 0);

  return false;
};
