/* eslint-disable prettier/prettier */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Metadata } from 'types/common';
import ReactTooltip from 'react-tooltip';
import { HoverTooltip } from '@components/HoverTooltip';
import { FeedbackButton } from '@components/FeedbackButton';
import { PageContext } from '@templates/types';
import { classNames } from '../../utils/classNames';
import Header from './Header/Header';
import ReleaseNotes from './ReleaseNotes/ReleaseNotes';
import CookiesPopup from './CookiesPopup/CookiesPopup';
import Banner from './Banner/Banner';
import Footer from './Footer/Footer';
import styles from './Layout.module.css';
import '../../styles/main.css';
import { metadataDefault } from './constants';

interface Props {
  children: React.ReactElement[] | React.ReactElement;
  metadata: Metadata;
  pageContext: PageContext;
  location: { pathname: string };
}

function Layout(props: Props): React.ReactElement {
  const { children } = props;
  let metadata = props.pageContext?.metadata;
  let hideBanner;
  let disableHeaderLink;

  if (!metadata) {
    metadata = metadataDefault;
    metadata.path = props.location.pathname;
    hideBanner = true;
    disableHeaderLink = true;
  }

  return (
    <>
      <Helmet title={metadata.title} defer={false}>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <meta name="author" content="BCD Travel" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${metadata.siteUrl}${metadata.path}/`} />
        <meta property="og:title" content={metadata.title} />
        <meta property="og:description" content={metadata.description} />
        <meta property="og:image" content={`${metadata.siteUrl}/ts_meta_logo.jpg`} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="220" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="TripSource" />
        <meta name="twitter:title" content={metadata.title} />
        <meta name="twitter:description" content={metadata.description} />
        <meta name="twitter:image" content={`${metadata.siteUrl}/ts_meta_logo.jpg`} />
        <meta name="twitter:card" content="summary" />
        {metadata.isRobotsEnabled ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>

      <ReleaseNotes releaseNotes={props.pageContext.releaseNotes} />

      <Header disableHeaderLink={disableHeaderLink} />
      {!hideBanner && <Banner />}
      <div className={styles.bodyWrapper}>
        <div className={classNames([styles.bodyContainer, hideBanner ? styles.withoutBanner : ''])}>
          {children}
        </div>
        <Footer />
      </div>
      <FeedbackButton />
      <CookiesPopup />
      <ReactTooltip
        className={styles.tooltip}
        offset={{ top: 10, left: 10, bottom: 10, right: 10 }}
        backgroundColor="transparent"
        arrowColor="transparent"
        getContent={(dataTip: string) => <HoverTooltip {...JSON.parse(dataTip)} />}
      />
    </>
  );
}

export default Layout;
