export const languagesListConfig = [
  {
    value: 'auto|zh-CN',
    label: 'Chinese',
  },
  {
    value: 'auto|nl',
    label: 'Dutch',
  },
  {
    value: 'auto|en',
    label: 'English',
  },
  {
    value: 'auto|fr',
    label: 'French',
  },
  {
    value: 'auto|de',
    label: 'German',
  },
  {
    value: 'auto|it',
    label: 'Italian',
  },
  {
    value: 'auto|ja',
    label: 'Japanese',
  },
  {
    value: 'auto|no',
    label: 'Norwegian',
  },
  {
    value: 'auto|pl',
    label: 'Polish',
  },
  {
    value: 'auto|pt',
    label: 'Portuguese',
  },
  {
    value: 'auto|ru',
    label: 'Russian',
  },
  {
    value: 'auto|es',
    label: 'Spanish',
  },
  {
    value: 'auto|sv',
    label: 'Swedish',
  },
];

export const GOOGLE_TRANSLATE_COOKIE_NAME = 'googtrans';

export const noTranslateClassName = 'notranslate';

export const scriptSrc =
  'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2';
