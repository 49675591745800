import React, { PropsWithChildren } from 'react';

import styles from './HoverTooltip.module.css';
import { HoverTooltipsOptions } from './types';

function HoverTooltip(props: PropsWithChildren<HoverTooltipsOptions>): React.ReactElement {
  const { header, text } = props;

  return (
    <div className={styles.container}>
      {!!header && <p className={styles.header}>{header}</p>}
      <p>{text}</p>
    </div>
  );
}

export default HoverTooltip;
