import React, { useCallback, useEffect, useState } from 'react';

import { classNames } from 'utils/classNames';

import { ContentNode } from '@components/ContentNode';
import * as contentful from 'types/contentful';
import { CloseIcon } from '../../CustomizedIcons/CloseIcon';
import { isReleaseNotesHiddenConstant, releaseNotesTime, releaseNotesHide } from './constants';

import styles from './ReleaseNotes.module.css';

type ReleaseNotesProps = {
  releaseNotes: { description: { json: contentful.Content }; title: string; updatedAt: string };
};

const ReleaseNotes = (props: ReleaseNotesProps): React.ReactElement => {
  const [isReleaseNotesHidden, setIsReleaseNotesHidden] = useState(true);

  const { releaseNotes } = props;

  useEffect(() => {
    const savedTime =
      (typeof window !== 'undefined' && window.localStorage?.getItem(releaseNotesTime)) || '';

    if (releaseNotes?.updatedAt > savedTime) {
      window.localStorage.setItem(isReleaseNotesHiddenConstant, 'false');
      window.localStorage.setItem(releaseNotesTime, releaseNotes.updatedAt);

      setIsReleaseNotesHidden(false);
    } else {
      setIsReleaseNotesHidden(
        typeof window === 'undefined' ||
          window.localStorage.getItem(isReleaseNotesHiddenConstant) == 'true',
      );
    }
  }, []);

  const handleCookiesClick = useCallback((): void => {
    setIsReleaseNotesHidden(true);

    window.localStorage.setItem(isReleaseNotesHiddenConstant, 'true');
    window.localStorage.setItem(releaseNotesTime, releaseNotes.updatedAt);
  }, []);

  return (
    <div
      className={classNames([
        isReleaseNotesHidden ||
        props.releaseNotes?.title.trim() === releaseNotesHide ||
        !props.releaseNotes?.description
          ? styles.hidden
          : styles.block,
      ])}
    >
      <div className={styles.container}>
        <div className={styles.title}>{props.releaseNotes?.title}</div>
        <div className={styles.description}>
          <ContentNode {...props.releaseNotes?.description?.json} lightColor={true} />
        </div>
        <button type="button" className={styles.button} onClick={handleCookiesClick}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default ReleaseNotes;
