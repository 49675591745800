import React from 'react';

import styles from './CloseIcon.module.css';

const CloseIcon = (): React.ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.icon}
  >
    <path
      d="M20.6666 12.2735L19.7266 11.3335L15.9999 15.0602L12.2733 11.3335L11.3333
     12.2735L15.0599 16.0002L11.3333 19.7268L12.2733 20.6668L15.9999 16.9402L19.7266
     20.6668L20.6666 19.7268L16.9399 16.0002L20.6666 12.2735Z"
    />
    <path
      d="M6 1H26V-1H6V1ZM31 6V26H33V6H31ZM26 31H6V33H26V31ZM1 26V6H-1V26H1ZM6
     31C3.23858 31 1 28.7614 1 26H-1C-1 29.866 2.13401 33 6 33V31ZM31 26C31 28.7614
      28.7614 31 26 31V33C29.866 33 33 29.866 33 26H31ZM26 1C28.7614 1 31 3.23858 31
      6H33C33 2.13401 29.866 -1 26 -1V1ZM6 -1C2.13401 -1 -1 2.13401 -1 6H1C1 3.23858
      3.23858 1 6 1V-1Z"
    />
  </svg>
);

export default CloseIcon;
