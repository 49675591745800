import React from 'react';

import { useScreenMatchOnMobile } from '@hooks/useScreenMatchOnMobile';

import styles from './Banner.module.css';

const Banner = (): React.ReactElement => {
  const isMobile = useScreenMatchOnMobile();

  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.banner}>
        <h1>COVID-19 {!isMobile ? 'Information' : 'Info'} Hub </h1>

        <h2>
          At BCD Travel we’re committed to help you make informed, confident and safe travel
          decisions.
        </h2>
      </div>
    </div>
  );
};

export default Banner;
