import React from 'react';
import LanguageTranslator from '@components/LanguageTranslator';
import styles from './Header.module.css';

interface Props {
  disableHeaderLink?: boolean;
}

function Header(): React.ReactElement {
  return (
    <div className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <a target="_blank" rel="noopener noreferrer" href="https://tripsource.com/">
            <img className={styles.logo} src="/logo_en.svg" alt="Site logo" />
          </a>
        </div>
        <div className={styles.langContainer}>
          <LanguageTranslator />
        </div>
      </div>
    </div>
  );
}

export default Header;
