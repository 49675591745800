export const LanguageSelectStyles = {
  container: base => ({
    ...base,
    padding: '5px 0',
    width: 112,
  }),
  control: base => ({
    ...base,
    minHeight: 'fit-content',
    border: 'none',
    backgroundColor: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: base => ({
    ...base,
    alignSelf: 'center',
    position: 'relative',
  }),
  dropdownIndicator: (base, state) => {
    const { menuIsOpen } = state.selectProps;

    return {
      ...base,
      padding: 3,
      borderStyle: 'solid',
      borderWidth: '0 2px 2px 0',
      transform: menuIsOpen ? 'rotate(-135deg)' : 'rotate(45deg)',
      borderColor: '#5A88C6',
      '& > svg': {
        display: 'none',
      },
      position: 'absolute',
      right: 14,
      top: menuIsOpen ? -1 : -5,
      margin: 'auto',
      '@media (max-width: 720px)': {
        right: 13,
      },
    };
  },
  menuList: base => ({
    ...base,
    minHeight: '386px',
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
  }),
  singleValue: base => ({
    ...base,
    marginLeft: 6,
    marginRight: 16,
    font: '600 14px/16px "Proxima Nova", sans-serif',
    right: 15,
    color: '#ffffff',
    '@media (max-width: 720px)': {
      marginRight: 20,
    },
  }),
  option: base => ({
    ...base,
    fontSize: 14,
    padding: '5px 5px 5px 10px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(185, 199, 212, 0.3)',
      color: '#3763A0',
    },
    color: '#404040',
    cursor: 'pointer',
  }),
};
