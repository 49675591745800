import React from 'react';
import * as contentful from 'types/contentful';
import {
  UNORDERED_LIST,
  DOCUMENT,
  LIST_ITEM,
  PARAGRAPH,
  TEXT,
  HYPERLINK,
} from 'constants/contentNodeTypes';
import styles from './ContentNode.module.css';
import { classNames } from '../../utils/classNames';

export function ContentNode(
  props: React.PropsWithChildren<contentful.Content> & { lightColor?: boolean },
): React.ReactElement {
  switch (props.nodeType) {
    case DOCUMENT:
      return (
        <div className={classNames([styles.document, props.lightColor ? styles.lightColor : ''])}>
          {props.content.map((item, i) => (
            <ContentNode key={i.toString()} {...item} />
          ))}
        </div>
      );
    case UNORDERED_LIST:
      return (
        <ul className={styles.list}>
          {props.content.map((item, i) => (
            <ContentNode key={i.toString()} {...item} />
          ))}
        </ul>
      );
    case LIST_ITEM: {
      const hasCheckMark = props.content.some(it =>
        (it as contentful.ContentParagraph).content.some(it =>
          (it as contentful.ContentText).value?.startsWith('[]'),
        ),
      );

      return (
        <li className={classNames([styles.listItem, hasCheckMark ? styles.listItemCheckMark : ''])}>
          {hasCheckMark ? <div className={styles.checkMark} /> : null}
          {props.content.map((item, i) => (
            <ContentNode key={i.toString()} {...item} />
          ))}
        </li>
      );
    }
    case PARAGRAPH: {
      const childItems = props.content.filter(
        item => item.nodeType !== TEXT || !!(item as contentful.ContentText).value,
      );

      return childItems.length > 0 ? (
        <p className={styles.paragraph}>
          {childItems.map((item, i) => (
            <ContentNode key={i.toString()} {...item} />
          ))}
        </p>
      ) : (
        <></>
      );
    }
    case TEXT: {
      const fixedValue = (props.value.startsWith('[]') ? props.value.slice(2) : props.value) ?? '';
      let value = <>{fixedValue.replace(/\s+/g, ' ')}</>;
      if (props.marks.some(m => m.type === 'bold')) {
        value = <b>{value}</b>;
      }
      if (props.marks.some(m => m.type === 'italic')) {
        value = <i>{value}</i>;
      }
      if (props.marks.some(m => m.type === 'underline')) {
        value = <u>{value}</u>;
      }

      return value;
    }
    case HYPERLINK: {
      return (
        <a target="_blank" rel="noreferrer" href={props.data.uri}>
          {props.content.map((item, i) => (
            <ContentNode key={i.toString()} {...item} />
          ))}
        </a>
      );
    }

    default:
      return <></>;
  }
}
