/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useCallback } from 'react';

import icon from '../../../static/feedbackModal/close.svg';
import hoverIcon from '../../../static/feedbackModal/hoverClose.svg';

import styles from './FeedbackButton.module.css';

function FeedbackButton(): React.ReactElement {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const renderSurvey = () => {
    // @ts-ignore
    global.renderSurvey('', '', '');

    setIsModalOpen(true);
  };

  useEffect(() => {
    document?.querySelector('#mfpembed_iconDiv')?.addEventListener('click', closeModal);

    document.body.className = isModalOpen ? 'remove-scroll' : '';

    if (isModalOpen) {
      window.scrollTo(0, 0);
    }

    return () =>
      document?.querySelector('#mfpembed_iconDiv')?.removeEventListener('click', closeModal);
  }, [closeModal, isModalOpen]);

  useEffect(() => {
    const img: any = document.getElementById('MfpEmbed_CrossButton');

    document?.querySelector('#mfpembed_iconDiv')?.addEventListener('mouseover', () => {
      img.src = hoverIcon;
    });

    document?.querySelector('#mfpembed_iconDiv')?.addEventListener('mouseout', () => {
      img.src = icon;
    });

    if (img) {
      img.src = icon;
    }
  });

  return (
    <button type="button" className={styles.feedbackButton} onClick={renderSurvey}>
      Feedback
    </button>
  );
}

export default FeedbackButton;
