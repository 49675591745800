// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-covid-map-covid-map-tsx": () => import("./../../../src/templates/covidMap/covidMap.tsx" /* webpackChunkName: "component---src-templates-covid-map-covid-map-tsx" */),
  "component---src-templates-international-international-tsx": () => import("./../../../src/templates/international/international.tsx" /* webpackChunkName: "component---src-templates-international-international-tsx" */),
  "component---src-templates-need-to-know-need-to-know-tsx": () => import("./../../../src/templates/needToKnow/needToKnow.tsx" /* webpackChunkName: "component---src-templates-need-to-know-need-to-know-tsx" */),
  "component---src-templates-policy-airline-carriers-policy-airline-carriers-tsx": () => import("./../../../src/templates/policyAirlineCarriers/policyAirlineCarriers.tsx" /* webpackChunkName: "component---src-templates-policy-airline-carriers-policy-airline-carriers-tsx" */),
  "component---src-templates-policy-airports-policy-airports-tsx": () => import("./../../../src/templates/policyAirports/policyAirports.tsx" /* webpackChunkName: "component---src-templates-policy-airports-policy-airports-tsx" */),
  "component---src-templates-policy-car-rentals-policy-car-rentals-tsx": () => import("./../../../src/templates/policyCarRentals/policyCarRentals.tsx" /* webpackChunkName: "component---src-templates-policy-car-rentals-policy-car-rentals-tsx" */),
  "component---src-templates-policy-hotel-chains-policy-hotel-chains-tsx": () => import("./../../../src/templates/policyHotelChains/policyHotelChains.tsx" /* webpackChunkName: "component---src-templates-policy-hotel-chains-policy-hotel-chains-tsx" */),
  "component---src-templates-policy-rail-carriers-policy-rail-carriers-tsx": () => import("./../../../src/templates/policyRailCarriers/policyRailCarriers.tsx" /* webpackChunkName: "component---src-templates-policy-rail-carriers-policy-rail-carriers-tsx" */)
}

